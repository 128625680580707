import React, { useState, useEffect } from "react";
import {
  Table,
  Modal,
  message,
  Avatar,
  Pagination,
  Select,
  Button,
  Input,
} from "antd";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Switch } from "antd";
import { notifyError, notifySuccess, notifyWarn } from "../../components/Toast";
import "./style.scss";
import {
  PlayCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
  PlaySquareOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { DatePicker } from "antd";
import formatDate from "../../services/formatDate";
import moment from "moment";

import ReactPlayer from "react-player";
import { deleteVideo, getAllVideos, updateVideo, fetchVideoGraphData } from "../../services/videos";
import { ThumbnailPreviewer } from "../../components/ThumbnailPreviewer";
import LineChartComponentVideos from "../../components/LineChartComponenVideos";

const { Search } = Input;
const { Column } = Table;

const VideoTable = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setdeleteId] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [videoDetail, setVideoDetail] = useState({});
  const [videos, setVideos] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [IsPrivate, setIsPrivate] = useState(null);
  const [date, setIsdate] = useState(null);


  const [searchedText, setSearchedText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default to 10 rows per page

  const [filterObject, setfilterObject] = useState({
    isFeatured: "",
    isPrivate: "",
    date: "",
    name: "",
    startDate:null , endDate :null ,
  });


  const [reload, setReload] = useState(false);

  useEffect(() => {
    fetchVideosdData(pageNumber, rowsPerPage, filterObject);
  }, [pageNumber, rowsPerPage, reload]);


  console.log("videos", videos.length);

  // Fetch the data when the component mounts or when the filter changes


  const onChangePagination = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleDeleteVideo = () => {
    deleteVideo(deleteId)
      .then((resp) => {
        if (resp.type === "success") {
          fetchVideosdData(pageNumber, filterObject);
          setIsDeleteModalOpen(false);
          notifySuccess(
            "Video has been successfully deleted.",
            resp.message,
            "success"
          );
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };


  const fetchVideosdData = (pgnumber, rowsPerPage, obj) => {
    setIsLoading(true);

    getAllVideos(pgnumber, rowsPerPage, { ...obj })
      .then((result) => {
        if (result.type === "success") {
          setVideos(result?.data?.videos);
          setTotalUsers(result?.data?.totalvideos);
          setIsLoading(false);
        } else {
          setVideos([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        message.error("Failed to fetch videos");
        console.error(error);
        setIsLoading(false);
        setVideos([]);
      });
  };

  const handlePlayVideo = (record) => {
    setSelectedVideo(record);
    setModalVisible(true);
  };

  const handleDeleteImage = () => {
    let body = {
      thumbnailUrl:
        "https://fitn-bucket.s3.eu-west-2.amazonaws.com/1718204937278.jpeg",
    };

    updateVideo(deleteId, body)
      .then((resp) => {
        if (resp.type === "success") {
          notifySuccess("Thumbnail Replaced");
          fetchVideosdData(pageNumber, filterObject);
          setdeleteId(null);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleFeatureChange = (id, value) => {
    const body = { isFeatured: !value };
    updateVideo(id, body)
      .then((resp) => {
        if (resp.type === "success") {
          notifySuccess("Video Featured Update", resp.message);
          fetchVideosdData(pageNumber, filterObject);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      title: "ThumbNail",
      dataIndex: "thumbnailUrl",
      key: "thumbnailUrl",
      render: (text, record) => (
        <Avatar
          style={{ cursor: "pointer" }}
          onClick={() => {
            setdeleteId(record._id);
            setPreviewUrl(record.thumbnailUrl);
          }}
          size={45}
          src={record.thumbnailUrl ? record.thumbnailUrl : null}
          icon={!record.thumbnailUrl && <PlaySquareOutlined />}
        />
      ),
    },
    {
      title: "VideoName",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text, record) => <span>{record.category.name}</span>,
      sorter: (a, b) => a.category.name.localeCompare(b.category.name),
    },

    {
      title: "Created",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (text, record) => <span> {formatDate(record?.createdAt)}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },

    {
      title: "Featured",
      dataIndex: "isFeatured",
      key: "isFeatured",
      render: (text, record) => (
        <Switch
          checked={record.isFeatured}
          onChange={() => handleFeatureChange(record._id, record.isFeatured)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setVideoDetail(record);
              setIsDetailsModalOpen(true);
            }}
          >
            <EyeOutlined />
          </span>
          <span
            style={{ marginLeft: 16, cursor: "pointer" }}
            onClick={() => handlePlayVideo(record)}
          >
            <PlayCircleOutlined />
          </span>

          <span
            style={{ marginLeft: 16, cursor: "pointer" }}
            onClick={() => {
              setIsDeleteModalOpen(true);
              setdeleteId(record._id);
            }}
          >
            <DeleteOutlined />
          </span>
        </div>
      ),
    },
  ];

  const scrollConfig = {
    y: "20vh", // Set the maximum height in pixels or other CSS units
  };


  console.log("dateRange" , dateRange);
  

  const handleApplyFilter = () => {
    if (!selectedFeature && !IsPrivate && (!dateRange[0] || !dateRange[1])) {
      notifyWarn("Select a value first");
      return;
    }

    const startDate = dateRange[0] ? dateRange[0].toISOString() : null;
    const endDate = dateRange[1] ? dateRange[1].toISOString() : null;

    setfilterObject((prevFilterObject) => {
      const updatedFilter = {
        ...prevFilterObject,
        isPrivate: IsPrivate,
        isFeatured: selectedFeature,
        startDate:startDate , endDate :endDate ,
      };
      console.log("Filter Object:", updatedFilter); // Log the filter object
      return updatedFilter;
    });


  
    

    setfilterObject((prevFilterObject) => ({
      ...prevFilterObject,
      isPrivate: IsPrivate,
    }));

    setfilterObject((prevFilterObject) => ({
      ...prevFilterObject,
      isFeatured: selectedFeature,
    }));

    setReload(!reload);
  };

  const handleSearch = (value, _e, info) => {
    if (!value) {
      notifyWarn("Enter v value first");
      return;
    }

    setfilterObject((prevFilterObject) => ({
      ...prevFilterObject,
      name: value,
    }));

    setReload(!reload);
  };

  const handleDateChange = (dates) => {
    setDateRange(dates); // Set the selected date range
  };

  return (
    <div className="userContainer">
      <div className="tableInfo">
        <span style={{ color: "white" }} className="spanstyle">
          Videos
        </span>
        {previewUrl && (
          <ThumbnailPreviewer
            previewUrl={previewUrl}
            setPreviewUrl={setPreviewUrl}
            handleDeleteImage={handleDeleteImage}
          />
        )}
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <Select
            placeholder="isFeatured"
            value={selectedFeature}
            style={{
              width: 150,
            }}
            onChange={(value) => setSelectedFeature(value)}
            options={[
              {
                text: "Featured",
                value: "Featured",
              },
              {
                text: "Not Featured",
                value: "Not Featured",
              },
            ]}
          />

          <Select
            placeholder="isPrivate"
            value={IsPrivate}
            style={{
              width: 150,
            }}
            onChange={(value) => setIsPrivate(value)}
            options={[
              {
                text: "Private",
                value: "Private",
              },
              {
                text: "Public",
                value: "Public",
              },
            ]}
          />

      

<DatePicker.RangePicker 
  style={{ width: 150 }}
        onChange={handleDateChange} 
        format="YYYY-MM-DD" // Format for display
      />



          {selectedFeature || IsPrivate || date ? (
            <Button
              type="default"
              onClick={() => {
                setSelectedFeature(null);
                setIsPrivate(null);
                setIsdate(null);
                setfilterObject((prevFilterObject) => ({
                  ...prevFilterObject,
                  isPrivate: "",
                  isFeatured: "",
                  date: "",
                }));
                setReload(!reload);
              }}
            >
              Reset
            </Button>
          ) : null}
          <Button type="primary" onClick={handleApplyFilter}>
            Apply Filter
          </Button>
        </div>

        <Search
          placeholder="input search text"
          allowClear
          enterButton="Search"
          size="small"
          style={{
            width: 304,
          }}
          onSearch={handleSearch}
          onChange={(e) => {
            if (!e.target.value) {
              setfilterObject((prevFilterObject) => ({
                ...prevFilterObject,
                name: "",
              }));
              setSearchedText("");
              setReload(!reload);
            }
          }}
        />
      </div>

      <div className="tableData" style={{ border: "none !important", borderRadius: '0px !important' }} title={'Videos Growth'}>
        {videos && (
          <Table
            dataSource={videos}
            columns={columns}
            className="custom-table"
            loading={isLoading ? true : false}
            scroll={scrollConfig}
            pagination={{ pageSize: rowsPerPage }}
          />
        )}
      </div>

      <div className="paginationComp">
        {/* Pagination Component */}
        <Pagination
          defaultCurrent={1}
          total={totalUsers}
          onChange={onChangePagination}
          pageSize={rowsPerPage}
        />

        {/* Rows per page Select */}
        <Select
          placeholder="Rows per page"
          value={rowsPerPage}
          style={{ width: 150, marginLeft: '16px' }} // Margin for spacing
          onChange={(value) => {
            setRowsPerPage(value);
          }}
          options={[
            { label: "5 / page", value: 5 },
            { label: "10 / page", value: 10 },
            { label: "20 / page", value: 20 },
            { label: "50 / page", value: 50 },
          ]}
        />
        {/* Your button in the bottom right corner */}
      </div>
      <div className="videotable-data">
        <div className="site-layout-background">
          <div className="leftSide" style={{ minHeight: 360, background: "white", border: '1px solid white', borderRadius: '1rem', width: '100%' }}>

            <LineChartComponentVideos style={{ border: "none !important", borderRadius: '0px !important' }} title={'Total Videos'} />

          </div>
        </div>
      </div>
      <Modal
        title="Delete Alert!"
        open={isDeleteModalOpen}
        onOk={handleDeleteVideo}
        okText="Confirm"
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setdeleteId(null);
        }}
      >
        <p>Are you sure to Delete...</p>
      </Modal>

      <Modal
        title="Video Details!"
        open={isDetailsModalOpen}
        onOk={() => {
          setIsDetailsModalOpen(false);
          setVideoDetail({});
        }}
        onCancel={() => {
          setIsDetailsModalOpen(false);
          setVideoDetail({});
        }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span className="title" style={{ fontWeight: "bold" }}>
              video Thumbnail:
            </span>
            {/* <img
              src={videoDetail?.thumbnailUrl}
              alt="thumbnail"
              style={{
                width: "120px",
                maxHeight: "30px",
              }}
            /> */}

            <Avatar
              size={200}
              src={videoDetail?.thumbnailUrl ? videoDetail?.thumbnailUrl : null}
              icon={!videoDetail?.thumbnailUrl && <PlaySquareOutlined />}
            />
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Name :
            </span>
            <span className="detail">{videoDetail?.name?.slice(0, 15)}</span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Owner :
            </span>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <span className="title" style={{ fontWeight: "bold" }}>
                  Name:
                </span>
                <span className="detail">{videoDetail?.owner?.name}</span>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Profile Image:
            </span>

            <Avatar
              size={48}
              src={
                videoDetail.owner?.profilePic
                  ? videoDetail.owner?.profilePic
                  : null
              }
              icon={!videoDetail.owner?.profilePic && <UserOutlined />}
            />
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              IsFeatured :
            </span>
            <span className="detail">
              {videoDetail?.isFeatured ? "true" : "false"}
            </span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Likes :
            </span>
            <span className="detail">{videoDetail?.likes?.length}</span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Comments :
            </span>
            <span className="detail">{videoDetail?.comments?.length}</span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              CreatedAt :
            </span>
            <span className="detail">{videoDetail?.createdAt}</span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              UpdatedAt :
            </span>
            <span className="detail">{videoDetail?.updatedAt}</span>
          </div>
        </div>
      </Modal>

      <Modal
        title={selectedVideo.name}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        {selectedVideo && (
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "relative",
                paddingTop: "56.25%",
              }}
            >
              <ReactPlayer
                url={selectedVideo.url}
                controls
                width="100%"
                height="100%"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <h2>{selectedVideo.name}</h2>
              <p>Video description or other information</p>
              <div style={{ display: "flex", alignItems: "center" }}></div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default VideoTable;
