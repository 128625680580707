import React, { useState, useEffect } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchVideoGraphData } from "../services/videos";

// Function to group and combine video data by day, month, or year
const combineData = (uploadedVideoData, startDate, endDate, isMonthly, isYearly) => {
  const dataMap = new Map();

  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    const formattedDate = d.toISOString().split("T")[0];
    dataMap.set(formattedDate, { date: formattedDate, uploadedVideos: 0 });
  }

  uploadedVideoData.forEach((video) => {
    const videoDate = new Date(video.createdAt).toISOString().split("T")[0];
    if (dataMap.has(videoDate)) {
      dataMap.get(videoDate).uploadedVideos += 1;
    }
  });

  if (isMonthly) {
    const monthlyData = new Map();
    dataMap.forEach((value, key) => {
      const month = key.substring(0, 7); // YYYY-MM
      if (!monthlyData.has(month)) {
        monthlyData.set(month, { date: month, uploadedVideos: 0 });
      }
      monthlyData.get(month).uploadedVideos += value.uploadedVideos;
    });
    return Array.from(monthlyData.values());
  }

  if (isYearly) {
    const yearlyData = new Map();
    dataMap.forEach((value, key) => {
      const year = key.substring(0, 4); // YYYY
      if (!yearlyData.has(year)) {
        yearlyData.set(year, { date: year, uploadedVideos: 0 });
      }
      yearlyData.get(year).uploadedVideos += value.uploadedVideos;
    });
    return Array.from(yearlyData.values());
  }

  return Array.from(dataMap.values());
};

const LineChartComponentVideos = ({ title }) => {
  const [uploadedVideoData, setUploadedVideoData] = useState([]);
  const [totalVideos, setTotalVideos] = useState(0);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [activeButton, setActiveButton] = useState("Current Month");

  useEffect(() => {
    fetchGraphData();

    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    setDateRange([firstDayOfMonth, today]);
  }, []);

  const fetchGraphData = async () => {
    const data = await fetchVideoGraphData();
    if (data?.type === "success") {
      setUploadedVideoData(data?.data?.videos);
      setTotalVideos(data?.data?.totalVideos);
    }
  };

  const getDateRange = (label) => {
    const today = new Date();
    switch (label) {
      case "Current Month":
        return [new Date(today.getFullYear(), today.getMonth(), 1), today];
      case "Last Month":
        const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
        return [lastMonth, lastMonthEnd];
      case "6 Months":
        return [new Date(today.setMonth(today.getMonth() - 6)), new Date()];
      case "1 Year":
        return [new Date(today.setFullYear(today.getFullYear() - 1)), new Date()];
      case "From 2020":
        return [new Date(2020, 0, 1), today];
      default:
        return [new Date(today.getFullYear(), today.getMonth(), 1), today];
    }
  };

  const handleButtonClick = (range, label) => {
    setDateRange(range);
    setActiveButton(label);
  };

  const combinedData = combineData(
    uploadedVideoData,
    startDate,
    endDate,
    activeButton === "6 Months" || activeButton === "1 Year",
    activeButton === "From 2020"
  );

  const filteredData = combinedData.filter(
    (entry) => new Date(entry.date) >= startDate && new Date(entry.date) <= endDate
  );

  const buttonStyle = {
    padding: "0.5rem 1rem",
    margin: "0 0.5rem",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#4CAF50",
    color: "white",
    cursor: "pointer",
    transition: "background-color 0.3s",
  };

  const activeButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#00330d",
  };

  return (
    <div style={{ borderRadius: "1rem", boxShadow: "0 4px 6px blue", border: "1px solid #d1d5db", padding: "1rem" }}>
      <h2 style={{ color: "black", marginBottom: "30px", alignSelf: "center", textAlign: "center" }}>
        {title} : {totalVideos}
      </h2>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <button
          onClick={() => handleButtonClick(getDateRange("Current Month"), "Current Month")}
          style={activeButton === "Current Month" ? activeButtonStyle : buttonStyle}
        >
          Current Month
        </button>
        <button
          onClick={() => handleButtonClick(getDateRange("Last Month"), "Last Month")}
          style={activeButton === "Last Month" ? activeButtonStyle : buttonStyle}
        >
          Last Month
        </button>
        <button
          onClick={() => handleButtonClick(getDateRange("6 Months"), "6 Months")}
          style={activeButton === "6 Months" ? activeButtonStyle : buttonStyle}
        >
          6 Months
        </button>
        <button
          onClick={() => handleButtonClick(getDateRange("1 Year"), "1 Year")}
          style={activeButton === "1 Year" ? activeButtonStyle : buttonStyle}
        >
          1 Year
        </button>
        <button
          onClick={() => handleButtonClick(getDateRange("From 2020"), "From 2020")}
          style={activeButton === "From 2020" ? activeButtonStyle : buttonStyle}
        >
          From 2020
        </button>
        <div style={{ display: "flex", alignItems: "center" }}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setDateRange([date, endDate])}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
            dateFormat="yyyy/MM/dd"
          />
          <span style={{ margin: "0 10px" }}>to</span>
          <DatePicker
            selected={endDate}
            onChange={(date) => setDateRange([startDate, date])}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            dateFormat="yyyy/MM/dd"
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart data={filteredData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area type="monotone" dataKey="uploadedVideos" stroke="#8884d8" fill="#8884d8" name="Uploaded Videos" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartComponentVideos;
